import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Button } from 'lego-dist';
import './index.css';

import ThemingImg from '@images/icons/theming.png';
import PaintImg from '@images/icons/paint.png';
import BookImg from '@images/icons/book.png';
import LabImg from '@images/icons/lab.png';
import MobileImg from '@images/icons/mobile.png';
import HeartImg from '@images/icons/heart.png';


const IndexPage = ({ data }) => {
  const title = 'Kinedu Dev Documentation';

  return (
    <StaticQuery
      query={graphql`
        query IndexSiteQuery {
          site {
            siteMetadata {
              title
              sidebar {
                pages {
                  slug
                  title
                  pages {
                    slug
                    title
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const numberOfComponents = data.site.siteMetadata.sidebar.pages
          .find((page) => page.title === 'Components').pages.length;
        return (
          <div id="example" className="index">
            <Helmet
              title={
              title
                ? `${title} - ${data.site.siteMetadata.title}`
                : data.site.siteMetadata.title
            }
              meta={[
                {
                  name: 'description',
                  content:
                  'Documentation for Internal Components and general information',
                },
                {
                  name: 'keywords',
                  content: 'design system, style guide, documentation',
                },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <div className="full height">
              <div className="following bar">
                <div className="ui container">
                  <div className="ui large secondary network menu inverted">
                    {/* <div class="item">
                    <div class="ui logo shape">
                      <div class="sides">
                        <div class="active ui side">
                          <img class="ui image" src={SUILogo} />
                        </div>
                      </div>
                    </div>
                  </div> */}
                    {/* <a class="view-ui item">
                    <i class="sidebar icon"></i> Menu
                  </a> */}
                    {/* <div class="right menu inverted">
                    <div
                      class="ui language floating dropdown link item"
                      id="languages"
                    >
                      <i class="world icon"></i>
                      <div class="text">English</div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="masthead segment">
                <div className="ui container">
                  <div className="introduction">
                    <a
                      className="ui alpha version label"
                      href="https://github.com/Semantic-Org/Semantic-UI/blob/master/RELEASE-NOTES.md"
                    >
                      Alpha 3.0.1
                    </a>
                    <h1 className="ui inverted header">
                      <span className="library">Kinedu React Components</span>
                      <span className="tagline">
                        LEGO
                      </span>
                    </h1>
                    <div className="ui hidden divider" />

                    <Button
                      onClick={() => { window.open('/getting-started', '_self'); }}
                      color="primary"
                      fill
                      flat
                      rounded
                      maxWidth={300}
                    >
                      Documentation
                    </Button>
                  </div>
                </div>
              </div>
              <div className="ui stripe theming vertical segment">
                <div className="ui stackable very relaxed two column grid container">
                  <div className="center aligned column">
                    <h1 className="ui icon header">
                      <img src={ThemingImg} className="ui icon image" alt="kinedu_doc" />
                      Unbelievable Theming
                    </h1>
                    <p>
                      Lego comes equipped with high-level thematic variables that allow you to adapt the component to every Kinedu project.
                    </p>
                    <p>
                      You can also customize the component by extending it with Style components.
                    </p>
                    <div
                      className="ui large source button"
                      style={{ display: 'none' }}
                    >
                      <i className="docs code icon" />
                      {' '}
                      View Source
                    </div>
                  </div>
                  <div className="left aligned column">
                    <div className="show_item"><Button color="primary" fill flat rounded>Primary</Button></div>
                    <div className="show_item"><Button color="secondary" fill outline rounded>Secondary</Button></div>
                    <div className="show_item"><Button color="secondary" fill flat rounded disabled>Disabled</Button></div>
                  </div>
                </div>
                <div className="ui two column center aligned relaxed stackable source grid">
                  <div className="column">
                    <h3 className="ui header">Variable File</h3>
                    <div
                      className="variable code"
                      data-type="less"
                      data-preserve="true"
                    />
                  </div>
                  <div className="column">
                    <h3 className="ui header">Override File</h3>
                    <div
                      className="override code"
                      data-type="less"
                      data-preserve="true"
                    />
                  </div>
                </div>
              </div>
              <div className="feature alternate ui stripe vertical segment">
                <div className="ui three column center aligned divided relaxed stackable grid container">
                  <div className="row">
                    <div className="column">
                      <h2 className="ui icon header">
                        <img className="ui icon image" src={BookImg} alt="kinedu_doc" />
                        {`${numberOfComponents} components`}
                      </h2>
                      <p>
                        All components have options for themes and unit testing implemented in the latest version.
                      </p>
                    </div>
                    <div className="column">
                      <h2 className="ui icon header">
                        <img className="ui icon image" src={PaintImg} alt="kinedu_doc" />
                        Customize components
                      </h2>
                      <p>
                        Customize components with specific themes or extend them with style components
                      </p>
                    </div>
                    <div className="column">
                      <h2 className="ui icon header">
                        <img className="ui icon image" src={LabImg} alt="kinedu_doc" />
                        Continuous updates
                      </h2>
                      <p>
                        We continue to work to create new components and improve existing ones.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ui stripe community vertical segment">
                <div className="ui two column center aligned divided very relaxed stackable grid container">
                  <div className="row">
                    <div className="column">
                      <h2 className="ui icon header">
                        <img className="ui icon image" src={MobileImg} alt="kinedu_doc" />
                        Mobile First
                      </h2>
                      <p>
                        All Kinedu apps are thinking to be consumed in mobile devices, and all Lego componentes are built thinkin in mobile first
                      </p>
                    </div>
                    <div className="column">
                      <h2 className="ui icon header">
                        <img className="ui icon image" src={HeartImg} alt="kinedu_doc" />
                        You are the best partner
                      </h2>
                      <p>
                        If you need updates on a component or need to cover needs that the current components do not solve, you can create a ticket on github.
                      </p>
                      <Button
                        onClick={() => { window.open('https://github.com/Kinedu/lego/issues', '_blank'); }}
                        color="secondary"
                        fill
                        outline
                        rounded
                        maxWidth={300}
                      >
                        Create a ticket
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ui alternate stripe vertical segment">
                {/* <div class="ui stackable center aligned grid container">
                <div class="fourteen wide column">
                  <h1 class="ui icon header">
                    <img class="ui inline icon image" src={RocketImg} alt='kinedu_doc' />
                  </h1>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default IndexPage;
